// Define a new component called button-counter
Vue.component('radio-resource', {
    props: ['channel','channelTones','primary'],
    data: function () {
        return {
        }
    },
    template: '<div class="card radio-interface" v-bind:class="{ \'emergency-active\':channel.emg!==0, \'green white-text\':channel.rx && channel.emg===0, \'red white-text\':channel.tx && channel.emg===0, \'blue darken-4 white-text\':channel.monitored && !channel.tx && !channel.rx && channel.emg===0, \'grey darken-4 grey-text\':!channel.monitored }">\n' +
        '<div class="card-contents" :data-tg-id="channel.tgID" :data-key="channel.key" :data-radio-id="channel.radioID">\n' +
        '    <img src="img/rssi-disconnected.png" class="rssi" v-if="!channel.monitored">\n' +
        '    <img src="img/rssi-connected.png" class="rssi" v-if="channel.monitored && !channel.rx && !channel.tx">\n' +
        '    <img src="img/rssi-tx.png" class="rssi" v-if="channel.tx">\n' +
        '    <img src="img/rssi-rx.png" class="rssi" v-if="channel.rx">\n' +
        '    <img src="img/secure.png" class="rssi" v-if="channel.encryptionkey">\n' +
        '    <p :class="{\'active-channel-id\':channel.monitored}">{{channel.name}}</p>\n' +
        '    <pre :class="{\'active-channel-id\':channel.monitored}">{{channel.tgID}}</pre>\n' +
        '    <button class="btn mon-button" v-bind:class="{ \'green\':channel.monitored , \'grey\':!channel.monitored}" v-on:click="channel.toggleMonitor"><img class="mon-button-img" src="img/mon.png"></button>\n' +
        '    <button class="btn ptt-button" v-on:click="channel.togglePTT" v-bind:class="{ \'red\':channel.ptt , \'disabled\':!channel.monitored}">PTT</button>\n' +
        '    <button class="btn hold-button" v-on:click="channel.toggleHold" v-bind:class="{ \'yellow black-text\':channel.hold , \'disabled\':!channel.monitored}">HOLD</button>\n' +
        '    <button class="btn pri-button" v-bind:class="{\'selected\':primary===channel.radioID, \'disabled\':!channel.monitored}" v-on:click="channel.togglePrimary">PRIM</button>\n' +
        '    <br/>\n' +
        '    <button class="btn emerg-button" v-bind:class="{\'disabled\':channel.emg===0}" v-on:click="channel.clearEmergency">CL/EMG</button>\n' +
        '    <a class=\'dropdown-trigger btn\' href=\'#\' v-bind:data-target=\'channel.key+"-"+channel.tgID+"-tonesel"\' v-bind:class="{\'disabled\':!channel.monitored}">TONESEL</a>\n' +
        '    <!-- \'channel.key+"-"+channel.tgID+"-tonesel"\' -->\n' +
        '    <!-- Dropdown Structure -->\n' +
        '    <ul v-bind:id=\'channel.key+"-"+channel.tgID+"-tonesel"\'  class=\'dropdown-content\'>\n' +
        '        <li v-for="tone in channelTones"><a v-on:click="channel.setTone" href="#" v-bind:data-tone-id="tone.toneID" v-bind:data-tone-icon="tone.toneIcon" v-bind:data-tone-text="tone.toneText"><img class="tone-icon" v-bind:src="\'/img/TONE-\'+tone.toneIcon+\'.png\'">{{tone.toneText}}</a></li>\n' +
        '    </ul>\n' +
        '    <button class="btn attn-button" v-bind:class="{\'disabled\':!channel.monitored}" v-on:click="channel.broadcastTone"><img class="tone-icon" v-bind:src="\'/img/TONE-\'+channel.toneIcon+\'.png\'">{{channel.toneText}}</button>\n' +
        '    <ul class="collection black-text recent-list" v-if="channel.expanded">' +
        '        <li class="collection-header -item"><div>Recent Calls</div></li>' +
        '        <li class="collection-item recent-list-item" v-for="callItem in channel.callList" v-bind:data-recent-type="callItem.type"><div>{{callItem.type}}: {{callItem.radioID}}</div></li>' +
        '    </ul>' +
        '    <p :class="{\'active-channel-id\':channel.monitored}">Status: {{channel.status}}</p>\n' +
        '</div>\n' +
        '</div>'
})